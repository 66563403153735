import { graphql, useStaticQuery } from "gatsby"
import { StrapiEvent } from "../interfaces/Event"

const usePastEventsQuery = () => {
  const pasteEventsQuery = useStaticQuery(graphql`
    query PastEventsQuery {
      allStrapiPastEvent(sort: { fields: from, order: ASC }) {
        nodes {
          ...STRAPI_PAST_EVENTFragment
        }
      }
    }
  `)
  const result: StrapiEvent[] = pasteEventsQuery?.allStrapiPastEvent?.nodes

  return result
}

export default usePastEventsQuery
