import { graphql, useStaticQuery } from "gatsby"
import { GatsbyImage } from "gatsby-plugin-image"
import React from "react"
import { Teacher } from "../../../interfaces/Teacher"

const Team = () => {
  const teachersQuery = useStaticQuery(graphql`
    query Teachers {
      allStrapiTeacher {
        nodes {
          fullName
          email
          phone
          picture {
            localFile {
              childImageSharp {
                gatsbyImageData
              }
            }
          }
          about {
            data {
              about
            }
          }
        }
      }
    }
  `)
  const teachers: Teacher[] = teachersQuery.allStrapiTeacher.nodes
  return (
    <div>
      <div className="grid grid-cols-2 gap-5 md:gap-10 lg:grid-cols-4">
        {teachers.map((teacher) => {
          return (
            <div className="card bg-base-200" key={teacher.fullName}>
              <div className="card-body items-center text-center">
                <div className="flex justify-center">
                  <div className="mask mask-squircle aspect-square w-1/2">
                    <GatsbyImage
                      image={
                        teacher.picture.localFile.childImageSharp
                          .gatsbyImageData
                      }
                      className="h-full"
                      alt={teacher.fullName}
                    />
                  </div>
                </div>
                <span className="card-title text-lg">{teacher.fullName}</span>
                <div className="flex flex-col items-center justify-center gap-3">
                  <span className="badge">{teacher.email}</span>
                  <span className="badge">{teacher.phone}</span>
                </div>
                <div className="list-none flex-row justify-center text-base">
                  <div
                    dangerouslySetInnerHTML={{
                      __html: teacher.about?.data?.about,
                    }}
                  />
                </div>
              </div>
            </div>
          )
        })}
      </div>
    </div>
  )
}

export default Team
