import { graphql, PageProps, useStaticQuery } from "gatsby"
import React from "react"
import PastEvents from "../components/speacialContent/PastEvents/PastEvents"
import Team from "../components/speacialContent/Team/Team"
import { Layout } from "../components/Layout/Layout"
import FileAttachmentsTable from "../components/FileAttachmentsTable/FileAttachmentsTable"
import { CustomPageProps, Page } from "../interfaces/Page"
interface DataProps {
  strapiPage: Page
}

const PageTemplate = (pageProps: CustomPageProps<DataProps>) => {
  const { node, slug }: any = pageProps.pageContext
  const { strapiPage } = pageProps.data

  return (
    <Layout activePage={{ slug: slug, title: strapiPage.title }}>
      <div className="prose prose-lg mb-20   prose-p:my-1">
        <h1>{strapiPage.title}</h1>
        {strapiPage && (
          <div
            dangerouslySetInnerHTML={{
              __html: strapiPage?.content?.data?.content,
            }}
          />
        )}
      </div>
      <div>
        {strapiPage?.specialContent?.componentID === "pastEvents" && (
          <PastEvents />
        )}
        {strapiPage?.specialContent?.componentID === "team" && <Team />}
      </div>
      <div>
        {strapiPage?.fileAttachments && strapiPage?.fileAttachments[0]?.file && (
          <div>
            {/* <div className="prose prose-xl mb-4">
              <h2>Soubory ke stažení</h2>
            </div> */}
            <FileAttachmentsTable files={strapiPage.fileAttachments} />
          </div>
        )}
      </div>
    </Layout>
  )
}
export const result = graphql`
  query PageQuery($slug: String) {
    strapiPage(slug: { eq: $slug }) {
      title
      slug
      content {
        data {
          content
        }
      }
      tags {
        ...STRAPI_TAGFragment
      }
      specialContent {
        componentID
      }
      fileAttachments {
        name
        file {
          localFile {
            extension
            publicURL
          }
        }
      }
    }
  }
`

export default PageTemplate
