import React from "react"
import { RiAttachmentLine } from "react-icons/ri"
import { StrapiFileAttachment } from "../../interfaces/Universal"

const FileAttachmentsTable = ({ files }: { files: StrapiFileAttachment[] }) => {
  return (
    <div className="card bg-base-200">
      <ul className="divide-y-2 divide-base-100">
        {files.map((file, index) => {
          return (
            <li className="" key={`file-${index}`}>
              <div className="flex justify-between py-4 px-6 md:text-xl ">
                <div className="">
                  <div className="flex shrink-0 items-center justify-center gap-4">
                    <RiAttachmentLine className="shrink-0" />
                    <span>{file.name}</span>
                  </div>
                </div>
                <div className="flex items-center justify-end">
                  <a
                    href={file?.file?.localFile?.publicURL}
                    className="link whitespace-nowrap"
                  >
                    Stáhnout soubor
                  </a>
                </div>
              </div>
            </li>
          )
        })}
      </ul>
    </div>
  )
}

export default FileAttachmentsTable
